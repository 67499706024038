import React, { useState } from "react"

import PrimarySlider from "./slider/PrimarySlider";
import ThumbnailSlider from "./slider/ThumbnailSlider"
import useFullSlider from "../../hooks/useFullSlider";

import style from "./vdp.module.scss"

export default function VDPImageSlider(props) {
  const { ListOfPhotos } = props.vehicle
  const { unavailableImg } = props

  const [ currentSlide, setCurrentSlide ] = useState(0)
  const [
    renderFullSlider, openFullSlider
  ] = useFullSlider(ListOfPhotos, currentSlide, unavailableImg)

  return (
    <>
    <section className={style["slider"]}>
      <div style={{ cursor: "pointer" }} onClick={openFullSlider}>
        <PrimarySlider slide={currentSlide} setSlide={setCurrentSlide}
          ListOfPhotos={ListOfPhotos} unavailableImg={unavailableImg} />
      </div>
      <ThumbnailSlider slide={currentSlide} setSlide={setCurrentSlide}
        ListOfPhotos={ListOfPhotos} unavailableImg={unavailableImg} />
    </section>
    { renderFullSlider() }
    </>
  )

}